<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md8>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Reset Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="emailAddress"
              prepend-icon="mdi-person"
              name="email"
              label="Email Address"
              type="text"
              :error-messages="resetPasswordError.emailAddress"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              prepend-icon="mdi-lock"
              name="email"
              label="New Password"
              type="password"
              :error-messages="resetPasswordError.newPassword"
              :error-count="6"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :to="{name: 'home'}">Cancel</v-btn>
          <v-btn color="primary" @click="onSubmit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      emailAddress: this.$route.query.emailAddress,
      newPassword: "",
      token: this.$route.query.token
    };
  },
  computed: mapState({
    resetPasswordError: state => state.resetPasswordError
  }),
  methods: {
    ...mapActions(["processResetPassword"]),
    onSubmit() {
      this.processResetPassword({
        emailAddress: this.emailAddress,
        token: this.token,
        newPassword: this.newPassword
      });
    },
    onResult() {}
  }
};
</script>